import React from 'react';
import Loadable from 'react-loadable';
import {
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  OrientationPartial,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Divider = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Divider;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/divider.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        scope={{
          React: React,
          Divider: Divider,
        }}
        code={snippet}
        platform="react"
        gitHubLink="themes/divider"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="divider" />

          <PropListItem name="color" types={['string']}>
            <Text>Determines the color of the divider.</Text>
            <List type="unordered">
              <li>
                <code>divider</code> (default)
              </li>
              <li>
                <code>level0-accent</code>
              </li>
              <li>
                <code>level1-accent</code>
              </li>
              <li>
                <code>level2-accent</code>
              </li>
              <li>
                <code>level3-accent</code>
              </li>
            </List>
          </PropListItem>

          <EnvironmentPartial />

          <OrientationPartial
            componentName="divider"
            defaultOrientation="horizontal"
          />

          <PropListItem name="width" types={['string']}>
            <Text>Determines how thick the divider is (in pixels).</Text>
            <List type="unordered">
              <li>
                <code>one</code> (default)
              </li>
              <li>
                <code>two</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Miscallaneous">
          <QaIdPartial componentName="divider" />
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
