import React from 'react';
import Loadable from 'react-loadable';
import {
  CodeSnippet,
  List,
  OrientationPartial,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  SpacePartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/rn-divider.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        disableCodeEditing
        code={snippet}
        platform="react-native"
        gitHubLink="theme/divider"
        layout="rn"
      />

      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="color" types={['string']}>
            <Text>Determines the color of the divider.</Text>
            <List type="unordered">
              <li>
                <code>divider</code> (default)
              </li>
              <li>
                <code>level0-accent</code>
              </li>
              <li>
                <code>level1-accent</code>
              </li>
              <li>
                <code>level2-accent</code>
              </li>
              <li>
                <code>level3-accent</code>
              </li>
            </List>
          </PropListItem>

          <OrientationPartial componentName="divider" />

          <PropListItem name="width" types={['string']} isRequired>
            <Text>Determines how thick the divider is (in pixels).</Text>
            <List type="unordered">
              <li>
                <code>one</code> (default)
              </li>
              <li>
                <code>two</code>
              </li>
            </List>
          </PropListItem>

          <SpacePartial />
        </PropList>
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
